import React, { useEffect, useLayoutEffect, memo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import Reducer from './AppProvider.reducer';

export function AppProvider({ children, loadUser, initializeWebSocket}) {
  useLayoutEffect(() => {
    loadUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    loadUser();
    initializeWebSocket();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return React.Children.only(children);
}

const mapStateToProps = createStructuredSelector({});

export function mapDispatchToProps(dispatch) {
  return bindActionCreators(Reducer.actionCreators, dispatch);
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  memo,
)(AppProvider);