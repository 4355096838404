import callApi from './axios';
import { createQueryParams } from '../utils/api';

export const getGroupsByQuery = async (searchParams = {}) => {
  const queryParams = createQueryParams(searchParams);
  const endpoint = `users/me/profiles/groups?${queryParams}`;
  return await callApi('GET', endpoint);
};

export const leaveGroupById = async (id) => {
  const endpoint = `users/me/profiles/groups/${id}/leave`;
  return await callApi('PATCH', endpoint);
};

const api = { getGroupsByQuery, leaveGroupById };

export default api;
