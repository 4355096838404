import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { useInjectReducer, useInjectSaga } from '../../utils/injectors';
import Reducer, { reducer, saga } from './GalleryModal.reducer';
import {
  makeSelector as makeStepperSelector,
  dispatcher as StepperDispatcher,
} from '../../components/Stepper/Stepper.reducer';

import {
  Dialog,
  IconButton,
  Icon,
  Grid,
} from '@mui/material';

import './GalleryModal.scss';
import FileViewer from '../../components/FileViewer/FileViewer';
import Stepper from '../../components/Stepper/Stepper';


const key = 'GalleryModal';

const GalleryModal = props => {
  const {
    payload,
  } = props;

  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  const items = payload.items ? payload.items : [];

  return (
    <>
      <section className={key}>

        <Stepper
          steps={items.map((x, index) => index)}
          stepPanels={items.map((x) => <GalleryItem item={x} {...props} />)}
          hideStepper
        />

      </section>
    </>
  );
};

GalleryModal.propTypes = {
  isEditing: PropTypes.bool,
  isOpen: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
  isOpen: Reducer.makeSelector('isOpen'),
  payload: Reducer.makeSelector('payload'),
  activeStep: makeStepperSelector('activeStep'),
});

export function mapDispatchToProps(dispatch) {
  return bindActionCreators(Reducer.actionCreators, dispatch);
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo,
)(GalleryModal);

const GalleryItem = props => {
  const {
    item,
    isOpen,
    activeStep,
    payload,

    hide,
  } = props;

  const items = payload.items ? payload.items : [];
  // const selectedItem = items.find(x => x.selected);
  // const selectedIndex = selectedItem ? items.findIndex((x) => x.url === selectedItem.url) : 0;

  const handleOnNext = () => {
    if (activeStep < items.length - 1) {
      StepperDispatcher.nextStep();
    }
    else {
      StepperDispatcher.setActiveStep(0);
    }
  };
  const handleOnBack = () => {
    if (activeStep >= items.length - 1) {
      StepperDispatcher.backStep();
    }
    else {
      StepperDispatcher.setActiveStep(items.length - 1);
    }
  };


  return (
    <Dialog
      open={isOpen}
      onClose={hide}
      fullWidth
      className={key}
    >

      <div className='top-controls'>
        <IconButton
          className='control-item'
          onClick={hide}
        >
          <Icon className='icon' color='error'>close</Icon>
        </IconButton>
      </div>

      <div className='viewer-wrapper'>
        <FileViewer viewMode={payload.viewMode} {...item} />
        {
          items.length > 1 &&
          <Grid
            className='bottom-controls'
            container
            justifyContent={'space-between'}
            alignItems='center'
          >

            <Grid item>
              <IconButton
                className='control-item'
                onClick={handleOnBack}
              >
                <Icon className="icon" color='primary'>arrow_back_ios</Icon>
              </IconButton>
            </Grid>

            <Grid item>
              <IconButton
                className='control-item'
                onClick={handleOnNext}
              >
                <Icon className="icon" color='primary'>arrow_forward_ios</Icon>
              </IconButton>
            </Grid>

          </Grid>
        }
      </div>

    </Dialog>
  );
};