import { MasterReducer } from '../../utils/store';
import { select } from 'redux-saga/effects';
import _ from 'lodash';

import * as Constants from '../../utils/constants';

export const Reducer = new MasterReducer(
  'DynamicDialog',
  {
    layers: [],
  },
  {
    show: (state, { payload }) => {
      var layerPayload = {
        layer: state.layers.length,
        isOpen: true,
        payload: payload,
        isEditing:
          payload.hasOwnProperty('isEditing') ?
          payload.isEditing :
          (
            payload.variant === Constants.DynamicDialog.Variant.Create.code ||
            payload.variant === Constants.DynamicDialog.Variant.Update.code
          ),
      };
      var layers = [
        ...state.layers,
        layerPayload,
      ];
      return {
        ...state,
        layers: layers,
      }
    },

    close: (state, { payload }) => {
      var lastLayer = state.layers.find(x => x.layer === state.layers.length - 1);
      var layers = state.layers.filter(x => x.layer !== lastLayer.layer);
      return {
        ...state,
        layers: layers,
        values: payload,
      }
    },
    _close: function* (returnAction, self) {
      var values = yield select(self.makeSelector('values'));
      if (window.location.href.includes('?') && values?.resetLocation) {
        window.location.replace(window.location.pathname);
      }
    },

    setLastLayerStates: (state, { payload }) => {
      var layers = state.layers.map(x => {
        if (x.layer === state.layers.length - 1) {
          return {
            ...x,
            ...payload,
          }
        }
        return x;
      });
      return {
        ...state,
        layers: layers,
      }
    },
    setLastLayerPayload: (state, { payload }) => {
      var layers = state.layers.map(x => {
        if (x.layer === state.layers.length - 1) {
          var layerPayload = {
            ...x.payload,
            ...payload,
          };
          return {
            ...x,
            payload: layerPayload,
          }
        }
        return x;
      });
      return {
        ...state,
        layers: layers,
      }
    },
  }
);

Reducer.makeLayerSelector = (layer, stateKey) => {
  return Reducer.makeCallbackSelector('layers', (layers) => {
    var target = layers.find(x => x.layer === layer);

    return target ? target[stateKey] : false;
  });
};

Reducer.makeLastLayerSelector = (stateKey) => {
  return Reducer.makeCallbackSelector('layers', (layers) => {
      const target = _.last(layers);
      return target ? target[stateKey] : false;
  });
};

export default Reducer;

export const reducer = Reducer.reducer;
export const saga = Reducer.saga;
