import React, { useEffect, memo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { Switch, Route, useLocation } from 'react-router-dom';
import { useInjectReducer, useInjectSaga } from '../../utils/injectors';
import { HelmetProvider } from "react-helmet-async";
import { Helmet } from 'react-helmet-async';
import Reducer, { reducer, saga } from '../AppProvider/AppProvider.reducer';

import './App.scss';
import * as Functions from '../../utils/functions';
import * as Constants from '../../utils/constants';
import 'flag-icons/css/flag-icons.min.css';

import {
  AuthenticatedRoute,
  ToAuthenticateRoute,
} from '../../utils/middleware';
import LoadingIndicator from '../../components/LoadingIndicator/LoadingIndicator';
import DynamicDialog from '../../components/DynamicDialog/DynamicDialog';
import GalleryModal from '../../components/GalleryModal/GalleryModal';
import Camera from '../../components/Camera/Camera';

const App = props => {
  const {
    loading,

    setStates,
  } = props;

  useInjectReducer({ key: 'app', reducer });
  useInjectSaga({ key: 'app', saga: saga });

  const location = useLocation();
  var routes = Functions.General.getConstant(Constants.Navigation.Route).list;

  useEffect(() => {
    setStates({
      location: location,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <HelmetProvider>
      <Helmet>
        <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
      </Helmet>
      
        <LoadingIndicator loading={loading} />

        <DynamicDialog />

        <GalleryModal />

        <Camera />

        <Switch>

          {
            routes.filter(x => !x.isSubRoute).map((route, index) => {
              if (route.routeTypeCode === Constants.Navigation.RouteType.Public.code) {
                return (
                  <Route
                    key={index}
                    exact
                    path={route.path}
                    component={React.lazy(() => import(`../../${route.componentPath}`))}
                  />
                )
              }
              if (route.routeTypeCode === Constants.Navigation.RouteType.Authenticated.code) {
                return (
                  <AuthenticatedRoute
                    key={index}
                    exact
                    path={route.path}
                    component={React.lazy(() => import(`../../${route.componentPath}`))}
                  />
                )
              }
              if (route.routeTypeCode === Constants.Navigation.RouteType.ToAuthenticate.code) {
                return (
                  <ToAuthenticateRoute
                    key={index}
                    exact
                    path={route.path}
                    component={React.lazy(() => import(`../../${route.componentPath}`))}
                  />
                )
              }
              
              return <></>;
            })
   
          }

        </Switch>

    </HelmetProvider>
  );
};

App.propTypes = {};

const mapStateToProps = createStructuredSelector({
  loading: Reducer.makeSelector('loading'),
});

export function mapDispatchToProps(dispatch) {
  return bindActionCreators(Reducer.actionCreators, dispatch);
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo
)(App);