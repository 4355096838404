import callApi from './axios';

export const getCurrentUser = async (body = {}) => {
  const endpoint = 'users/me';
  return await callApi('GET', endpoint, body);
};

export const updateUser = async (body = {}) => {
  const endpoint = 'users/me';
  return await callApi('PATCH', endpoint, body);
};

const api = { getCurrentUser, updateUser };

export default api;
