import { memo } from 'react';
import history from './history';
import configureStore from './configureStore';
import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';
import { LOCATION_CHANGE } from 'connected-react-router';
import { createActions, handleActions } from 'redux-actions';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { takeLatest } from 'redux-saga/effects';

const store = configureStore({}, history);

export const dispatchAction = (action) => {
  store.dispatch(action);
}

export const makeDispatcher = (actionCreators) => {
  var result = {};

  Object.keys(actionCreators).forEach((key) => {
    let item = actionCreators[key];
    result[key] = (...args) => {
      dispatchAction(item(...args));
    }
  });

  return result;
};

export const convertUnderscoreUpperCase = (str) => {
  var result = "";

  for (var i = 0; i < str.length; i++) {
    let item = str[i];
    if (item === item.toUpperCase()) {
      result += "_" + item.toUpperCase();
    }
    else {
      result += item.toUpperCase();
    }
  }

  return result;
}
export class MasterReducer {
  constructor(key, initialState, actions, keepState) {
    this.key = key;
    this.actions = actions;
    this.initialState = {
      values: false,
      loading: false,
      alert: false,
      result: false,
      ...initialState,
    }
    this.keepState = keepState;
    this.sagaNames = Object.keys(this.actions).filter(x => !x.includes('Return') && !x.includes('_'));

    this.actionCreators = createActions(
      'SET_STATES',
      'RESET_STATES',
      ...Object.keys(actions).map(x => convertUnderscoreUpperCase(x)),
      {
        prefix: key,
      },
    )

    var actionHandlers = {};
    Object.keys(actions).forEach(key => {
      actionHandlers[this.actionCreators[key]] = actions[key];
    });
    this.reducer = handleActions(
      {
        [this.actionCreators.setStates]: (state, { payload }) => ({
          ...state,
          ...payload,
        }),
        [this.actionCreators.resetStates]: () => (this.initialState),
        ...actionHandlers,
        [LOCATION_CHANGE]: (state) => {
          if (this.keepState) {
            return state;
          }

          return this.initialState;
        },
      },
      this.initialState,
    )

    this.originSelector = (state) => {
      return state[this.key] || this.initialState;
    }

    this.makeSelector = (stateKey) => {
      return createSelector(this.originSelector, state => state[stateKey]);
    }
    this.makeCallbackSelector = (stateKey, callback) => {
      return createSelector(this.originSelector, state => {
        return callback(state[stateKey]);
      });
    }

    this.dispatcher = makeDispatcher(this.actionCreators);

    this.sagaCallback = function* (self) {
      for (const key of self.sagaNames) {
        if (!key.includes('Return') && !key.includes('_')) {
          let reduxAction = self.actionCreators[key];
          let sagaAction = actions['_' + key];
          let returnAction = self.actionCreators[key.replace('_', '') + 'Return'];

          if (sagaAction) {
            yield takeLatest(reduxAction, sagaAction, returnAction, self);
          }
        }
      }
    }

    this.saga = () => {
      return this.sagaCallback(this);
    }

    this.connector = (map) => {
      const mapStateToProps = createStructuredSelector(map);
      const mapDispatchToProps = (dispatch) => {
        return bindActionCreators(this.actionCreators, dispatch);
      }
      return compose(
        connect(mapStateToProps, mapDispatchToProps),
        memo,
      );
    }
  }
};

export const Generator = {
  getActionPayload: (state, payload) => {
    var toSet = {};
    toSet.loading = true;
    if (payload?.ignoreLoader) {
      toSet.loading = state.loading;
    }
    return {
      ...state,
      values: payload,
      result: false,
      alert: false,
      ...toSet,
    }
  },
  getReturnActionPayload: (state, payload) => {
    var toSet = {};
    toSet.loading = false;
    if (payload?.ignoreLoader) {
      toSet.loading = state.loading;
    }
    if (!payload?.result) {
      toSet.alert = payload?.alert ? payload?.alert : payload;
    }
    return {
      ...state,
      result: payload?.result ? payload.result : false,
      ...toSet,
    }
  },
  getArrayModelActionPayload: (model, state, payload) => {
    var toSet = {};
    if (!payload?.keepModel) {
      toSet[model] = [];
    }
    return {
      ...Generator.getActionPayload(state, payload),
      ...toSet,
    }
  },
  getArrayModelReturnActionPayload: (model, state, payload) => {
    var toSet = {};
    toSet[model] = payload?.result ? payload.data : [];
    return {
      ...Generator.getReturnActionPayload(state, payload),
      ...toSet,
    }
  },
  getObjectModelActionPayload: (model, state, payload) => {
    var toSet = {};
    if (!payload?.keepModel) {
      toSet[model] = false;
    }
    return {
      ...Generator.getActionPayload(state, payload),
      ...toSet,
    }
  },
  getObjectModelReturnActionPayload: (model, state, payload) => {
    var toSet = {};
    toSet[model] = payload?.result ? payload.data : false;
    return {
      ...Generator.getReturnActionPayload(state, payload),
      ...toSet,
    }
  },

  makeAction: () => {
    return (state, { payload }) => (Generator.getActionPayload(state, payload));
  },
  makeReturnAction: () => {
    return {
      next: (state, { payload }) => (Generator.getReturnActionPayload(state, payload)),
      throw: (state, { payload }) => (Generator.getReturnActionPayload(state, payload)),
    }
  },

  makeArrayModelAction: (model) => {
    return (state, { payload }) => (Generator.getArrayModelActionPayload(model, state, payload));
  },
  makeArrayModelReturnAction: (model) => {
    return {
      next: (state, { payload }) => (Generator.getArrayModelReturnActionPayload(model, state, payload)),
      throw: (state, { payload }) => (Generator.getArrayModelReturnActionPayload(model, state, payload)),
    }
  },

  makeObjectModelAction: (model) => {
    return (state, { payload }) => (Generator.getObjectModelActionPayload(model, state, payload));
  },
  makeObjectModelReturnAction: (model) => {
    return {
      next: (state, { payload }) => (Generator.getObjectModelReturnActionPayload(model, state, payload)),
      throw: (state, { payload }) => (Generator.getObjectModelReturnActionPayload(model, state, payload)),
    }
  },

  makeActions: (functionName, sagaCallback) => {
    var result = {};
    result[functionName] = Generator.makeAction();
    result[`${functionName}Return`] = Generator.makeReturnAction();
    result[`_${functionName}`] = sagaCallback;
    return result;
  },
  makeArrayModelActions: (model, functionName, sagaCallback) => {
    var result = {};
    result[functionName] = Generator.makeArrayModelAction(model);
    result[`${functionName}Return`] = Generator.makeArrayModelReturnAction(model);
    result[`_${functionName}`] = sagaCallback;
    return result;
  },
  makeObjectModelActions: (model, functionName, sagaCallback) => {
    var result = {};
    result[functionName] = Generator.makeObjectModelAction(model);
    result[`${functionName}Return`] = Generator.makeObjectModelReturnAction(model);
    result[`_${functionName}`] = sagaCallback;
    return result;
  },
};

export const makeLoading = (keys) => {
  var combineLoading = false;
  keys.forEach((key) => {
    var originSelector = state => state[key];
    var makeSelector = (stateKey) => createSelector(originSelector, state => state[stateKey]);
    var loading = useSelector(makeSelector('loading'));
    if (loading) {
      combineLoading = true;
    }
  });

  return combineLoading;
};

export default store;