import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { useInjectReducer, useInjectSaga } from '../../utils/injectors';
import Reducer, { reducer, saga } from './DynamicDialog.reducer';

import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Slide,
  IconButton,
  Icon,
  Paper,
  DialogTitle,
  Grid,
} from '@mui/material';
import Draggable from 'react-draggable';
import { useReactToPrint } from 'react-to-print';

import './DynamicDialog.scss';
import * as Constants from '../../utils/constants';
import AnchorMenu from '../../components/AnchorMenu/AnchorMenu';

const key = 'DynamicDialog';

const DynamicDialog = props => {
  const {
    layers,
  } = props;

  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  return (
    <>
      <section className={key}>
        {layers.map((layer, index) => (
          <DialogItem
            key={index}
            item={layer}
            {...props}
          />
        ))}
      </section>
    </>
  );
};

DynamicDialog.propTypes = {
  layers: PropTypes.arrayOf(PropTypes.object),
};

const mapStateToProps = createStructuredSelector({
  layers: Reducer.makeSelector('layers'),
});

export function mapDispatchToProps(dispatch) {
  return bindActionCreators(Reducer.actionCreators, dispatch);
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo,
)(DynamicDialog);

const DialogItem = props => {
  const {
    item,
    close,
  } = props;

  const contentRef = React.useRef();
  const handlePrint = useReactToPrint({
    content: () => contentRef.current,
  });
  const handleCloseCallback = () => {
    if (item.payload.closeCallback) {
      item.payload.closeCallback();
    }
    close({
      resetLocation: item.payload.resetLocation
    });
  };

  return (
    <Dialog
      open={item.isOpen}
      onClose={handleCloseCallback}
      TransitionComponent={Transition}
      className={key}
      aria-labelledby="DynamicDialogTitle"
      PaperComponent={PaperComponent}
      fullWidth
      fullScreen={item.payload.fullScreen ? item.payload.fullScreen : false}
      maxWidth={item.payload.maxWidth ? item.payload.maxWidth : 'sm'}
    >
      {
        !item.payload.closeHeader &&
        <Grid
          container
          className="dialog-header"
          alignItems={'center'}
          justifyContent={'flex-end'}
        >

          <Grid className="dialog-title" item xs>
            <DialogTitle id="DynamicDialogTitle">
              <div className='title dot-overflow'>{item.payload.title}</div>
            </DialogTitle>
          </Grid>

          <Grid className="dialog-title" item>
            <ActionComponent handlePrint={handlePrint} {...props} />
          </Grid>

        </Grid>
      }

      <DialogContent className='dialog-content' dividers ref={contentRef}>
        {item.payload.content}
      </DialogContent>

      {
        !item.payload.hideSubmitAction &&
        <SubmitActionComponent {...props} />
      }
    </Dialog>
  );
};

const Transition = React.forwardRef(function Transition(propsa, ref) {
  return <Slide direction="up" ref={ref} {...propsa} />;
});

export function scrollToStart() {
  const dialogContent = document.querySelector('.DynamicDialog .dialog-content');
  if (dialogContent) {
    dialogContent.scroll(0, 0);
  };
};

const PaperComponent = props => {
  return (
    <Draggable handle="#DynamicDialogTitle" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
};

const ActionComponent = props => {
  const {
    item,
    handlePrint,

    show,
    close,
    setLastLayerStates,
  } = props;

  const { t } = useTranslation();

  const handleCloseCallback = () => {
    if (item.payload.closeCallback) {
      item.payload.closeCallback();
    }
    close({
      resetLocation: item.payload.resetLocation
    });
  };
  const handleDeleteCallback = (customConfirmation, overrideCallback) => {
    show({
      title: customConfirmation?.title ? customConfirmation.title : t('delete'),
      variant: Constants.DynamicDialog.Variant.Confirm.code,
      content: (
        <>
          {
            customConfirmation?.contents ?
              customConfirmation.contents.map((item, index) => {
                return (
                  <p key={index}>{item}</p>
                );
              }) :
              <p>{t('Message.delete_confirm')}</p>
          }
        </>
      ),
      cancelLabel: t('no'),
      acceptLabel: t('yes'),
      acceptCallback: () => {
        if (overrideCallback) {
          overrideCallback();
        }
        else {
          if (item.payload.deleteCallback) {
            item.payload.deleteCallback();
          }
        }
        close();
      }
    });
  };
  const PrintableElement = () => {
    return (
      <>
        {
          item.payload.printable &&
          <IconButton
            color="secondary"
            onClick={handlePrint}
          >
            <Icon className="icon">print</Icon>
          </IconButton>
        }
      </>
    );
  };
  const DeleteElement = () => {
    return (
      <>
        {
          item.payload.deleteAnchorItems && item.payload.deleteAnchorItems?.length !== 0 ?
            <AnchorMenu
              anchorButton={(
                <IconButton
                  color="secondary"
                >
                  <Icon className="icon">delete</Icon>
                </IconButton>
              )}
              menus={item.payload.deleteAnchorItems.map((item) => {
                item.callback = () => {
                  handleDeleteCallback(item.confirmation, item.overrideCallback);
                };
                return item;
              })}
            /> :
            <IconButton
              color="secondary"
              onClick={() => { handleDeleteCallback(item.payload.confirmation?.delete) }}
            >
              <Icon className="icon">delete</Icon>
            </IconButton>
        }
      </>
    );
  };
  const EditElement = () => {
    return (
      <>
        {
          item.payload.editAnchorItems && item.payload.editAnchorItems?.length !== 0 ?
            <AnchorMenu
              anchorButton={(
                <IconButton
                  color="secondary"
                >
                  <Icon className="icon">edit</Icon>
                </IconButton>
              )}
              menus={item.payload.editAnchorItems.map((x) => {
                x.callback = () => {
                  setLastLayerStates({
                    isEditing: true,
                  });
                  if (x.additionCallback) { x.additionCallback(); }
                };
                return x;
              })}
            /> :
            <IconButton
              color="secondary"
              onClick={() => {
                setLastLayerStates({
                  isEditing: true,
                });
              }}
            >
              <Icon className="icon">edit</Icon>
            </IconButton>
        }
      </>
    );
  };
  const EditOrShowElement = () => {
    return (
      <>
        {
          item.isEditing ?
            <IconButton
              color="secondary"
              onClick={() => {
                setLastLayerStates({
                  isEditing: false,
                });
              }}
            >
              <Icon className="icon">visibility</Icon>
            </IconButton> :
            <EditElement />
        }
      </>
    );
  }
  const CloseElement = () => {
    return (
      <IconButton
        color="error"
        aria-label="close"
        onClick={handleCloseCallback}
        className="close-btn"
      >
        <Icon>close</Icon>
      </IconButton>
    );
  }
  const AdditionActionsElement = () => {
    return (
      <>
        {
          item.payload?.additionActions?.map((item, index) => {
            const callback = () => {
              if (item.confirmation) {
                show({
                  title: item.confirmation.title,
                  variant: Constants.DynamicDialog.Variant.Confirm.code,
                  content: (
                    <>
                      {
                        item.confirmation.contents.map((item, index) => {
                          return (
                            <p key={index}>{item}</p>
                          );
                        })
                      }
                    </>
                  ),
                  cancelLabel: t('no'),
                  acceptLabel: t('yes'),
                  acceptCallback: () => {
                    item.callback();
                  }
                });
              }
              else {
                item.callback();
              }
            }
            return (
              <IconButton
                key={index}
                color="secondary"
                onClick={callback}
              >
                <Icon className="icon">{item.icon}</Icon>
              </IconButton>
            );
          })
        }
      </>
    );
  }

  switch (item.payload.variant) {
    case Constants.DynamicDialog.Variant.Create.code:
      return (
        <DialogActions>

          <AdditionActionsElement />

          <PrintableElement />

          <CloseElement />

        </DialogActions>
      );
    case Constants.DynamicDialog.Variant.Update.code:
      return (
        <DialogActions>

          <AdditionActionsElement />

          <PrintableElement />

          {
            item.payload.deleteCallback || item.payload.deleteAnchorItems ?
              <DeleteElement /> : <></>
          }

          <EditOrShowElement />

          <CloseElement />

        </DialogActions>
      );
    case Constants.DynamicDialog.Variant.Show.code:
      return (
        <DialogActions>

          <AdditionActionsElement />

          <PrintableElement />

          {
            item.payload.deleteCallback || item.payload.deleteAnchorItems ?
              <DeleteElement /> : <></>
          }

          <EditOrShowElement />

          <CloseElement />

        </DialogActions>
      );
    case Constants.DynamicDialog.Variant.Confirm.code:
      return (
        <DialogActions>

          <AdditionActionsElement />

          <PrintableElement />

          <CloseElement />

        </DialogActions>
      );
    case Constants.DynamicDialog.Variant.Info.code:
      return (
        <DialogActions>

          <AdditionActionsElement />

          <PrintableElement />

          <CloseElement />

        </DialogActions>
      );
    case Constants.DynamicDialog.Variant.Panel.code:
      return (
        <DialogActions>

          <AdditionActionsElement />

          <PrintableElement />

          <CloseElement />

        </DialogActions>
      );
    default:
      return (<></>);
  }
};

const SubmitActionComponent = props => {
  const {
    item,

    show,
    close,
  } = props;

  const { t } = useTranslation();

  const handleCloseCallback = () => {
    if (item.payload.closeCallback) {
      item.payload.closeCallback();
    }
    close();
  };
  const handleAcceptCallback = () => {
    if (item.payload.acceptCallback) {
      item.payload.acceptCallback();
    }
    close();
  };
  const handleSubmitCallback = () => {
    // { cancelable: true } required for Firefox
    // https://github.com/facebook/react/issues/12639#issuecomment-382519193
    const target = document.getElementById(item.payload.formId);

    if (target) {
      target.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
    }
  };
  const AdditionButtonsElement = () => {
    return (
      <>
        {
          item.payload?.additionButtons?.map((item, index) => {
            const itemCallback = async () => {
              if (item.submitCallback) {
                await item.submitCallback();
                handleSubmitCallback();
              }
              else{
                item.callback();
              }
            }
            const callback = () => {
              if (item.confirmation) {
                show({
                  title: item.confirmation.title,
                  variant: Constants.DynamicDialog.Variant.Confirm.code,
                  content: (
                    <>
                      {
                        item.confirmation.contents.map((item, index) => {
                          return (
                            <p key={index}>{item}</p>
                          );
                        })
                      }
                    </>
                  ),
                  cancelLabel: t('no'),
                  acceptLabel: t('yes'),
                  acceptCallback: itemCallback,
                });
              }
              else {
                itemCallback();
              }
            }
            return (
              <Button key={index} onClick={callback} {...item.buttonProps}>
                {item.label}
              </Button>
            );
          })
        }
      </>
    );
  }

  switch (item.payload.variant) {
    case Constants.DynamicDialog.Variant.Create.code:
      return (
        <DialogActions>

          {
            item.payload.closeHeader &&
            <Button onClick={handleCloseCallback} variant="outlined" color="secondary">
              {item.payload.cancelLabel ? item.payload.cancelLabel : t('cancel')}
            </Button>
          }

          <AdditionButtonsElement />

          {
            !item.payload.closeSubmitButton &&
            <Button
              variant="contained"
              color="primary"
              type="submit"
              onClick={handleSubmitCallback}
            >
              {item.payload.submitLabel ? item.payload.submitLabel : t('create')}
            </Button>
          }

        </DialogActions>
      );
    case Constants.DynamicDialog.Variant.Update.code:
      return (
        <DialogActions>

          {
            item.payload.closeHeader &&
            <Button onClick={handleCloseCallback} variant="outlined" color="secondary">
              {item.payload.cancelLabel ? item.payload.cancelLabel : t('cancel')}
            </Button>
          }

          <AdditionButtonsElement />

          {
            (item.isEditing && !item.payload.closeSubmitButton) &&
            <Button
              variant="contained"
              color="primary"
              type="submit"
              onClick={handleSubmitCallback}
            >{item.payload.submitLabel ? item.payload.submitLabel : t('update')}</Button>
          }

        </DialogActions>
      );
    case Constants.DynamicDialog.Variant.Show.code:
      return (
        <DialogActions>

          {
            item.payload.closeHeader &&
            <Button onClick={handleCloseCallback} variant="outlined" color="secondary">
              {item.payload.cancelLabel ? item.payload.cancelLabel : t('cancel')}
            </Button>
          }

          <AdditionButtonsElement />

          {
          (item.isEditing && !item.payload.closeSubmitButton) &&
            <Button
              variant="contained"
              color="primary"
              type="submit"
              onClick={handleSubmitCallback}
            >{item.payload.submitLabel ? item.payload.submitLabel : t('update')}</Button>
          }

        </DialogActions>
      );
    case Constants.DynamicDialog.Variant.Confirm.code:
      return (
        <DialogActions>

          <Button onClick={handleCloseCallback} color="primary">
            {item.payload.cancelLabel ? item.payload.cancelLabel : t('no')}
          </Button>

          <Button onClick={handleAcceptCallback} color="primary" autoFocus>
            {item.payload.acceptLabel ? item.payload.acceptLabel : t('yes')}
          </Button>

        </DialogActions>
      );
    case Constants.DynamicDialog.Variant.Info.code:
      return (
        <DialogActions>

          <Button onClick={handleAcceptCallback} color="primary" autoFocus>
            {item.payload.acceptLabel ? item.payload.acceptLabel : t('yes')}
          </Button>

        </DialogActions>
      );
    default:
      return (<></>);
  }
};