import callApi from './axios';
import { createQueryParams } from '../utils/api';

export const getMyEventsByQuery = async (searchParams = {}) => {
  const queryParams = createQueryParams(searchParams);
  const endpoint = `users/me/profiles/events?${queryParams}`;
  return await callApi('GET', endpoint);
};

const api = { getMyEventsByQuery };

export default api;
