import React from 'react';
import {
  Popover,
  List,
  ListItem,
  Grid,
  Icon,
} from '@mui/material';
import classNames from "classnames";
import useLongPress from "../../utils/useLongPress";

import './AnchorMenu.scss';

const key = 'AnchorMenu';

const AnchorMenu = props => {
  const {
    className,
    anchorButton,
    menus,
    noRecordMessage,
    longPress,

    pressCallback,
  } = props;

  const [menuAnchor, setMenuAnchor] = React.useState(null);

  const handleClick = ({ target }) => {
    if (!longPress) {
      setMenuAnchor(target);
    }

    if (pressCallback) {
      pressCallback();
    }
  }
  const handleOnLongPress = useLongPress(({ target }) => {
    if (longPress) {
      setMenuAnchor(target)
    }
  }, handleClick);

  return (
    <>
      <section           
        className={classNames(
            key,
            className
        )}
      >
        <div
          className="anchor-button"
          {...handleOnLongPress}
        >
          {anchorButton}
        </div>
        <div
          className="anchor-menu"
        >
          <Popover
            open={!!menuAnchor}
            anchorEl={menuAnchor}
            onClose={() => setMenuAnchor(null)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
          >
            <div className={key + 'Popover'}>
              <List className="list">
                {
                  menus.length === 0 && noRecordMessage &&
                  <ListItem
                    className="item"
                    button
                    onClick={() => {
                      setMenuAnchor(null);
                    }}
                  >
                    {noRecordMessage}
                  </ListItem>
                }
                {menus?.map((item, index) => (
                  <ListItem
                    className="item"
                    button
                    key={index}
                    onClick={() => {
                      setMenuAnchor(null);
                      if (item.callback) {
                        item.callback();
                      }
                    }}
                  >
                    <Grid container alignItems='center'>
                      {
                        item.iconElement &&
                        <Grid item className='icon'>
                          {item.iconElement}
                        </Grid>
                      }
                      {
                        item.icon &&
                        <Grid item className='icon'>
                          <Icon className="icon">{item.icon}</Icon>
                        </Grid>
                      }
                      <Grid item className='content'>
                        {item.content}
                      </Grid>
                    </Grid>
                  </ListItem>
                ))}
              </List>
            </div>
          </Popover>
        </div>
      </section>
    </>
  );
};

export default AnchorMenu;