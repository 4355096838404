import { MasterReducer } from '../../utils/store';
import { select, put } from 'redux-saga/effects';

import {
  setActiveStep,
} from '../../components/Stepper/Stepper.reducer';

export const Reducer = new MasterReducer(
  'GalleryModal',
  {
    isOpen: false,
    payload: {},
  },
  {
    show: (state, { payload }) => {
      return {
        ...state,
        isOpen: true,
        // avoid can't re-rendering
        payload: payload ? JSON.parse(JSON.stringify(payload)) : {},
      }
    },
    _show: function* (returnAction, self) {
      const payload = yield select(self.makeSelector('payload'));

      if (payload) {
        const items = payload.items;
        const selectedItem = items.find(x => x.selected);
        const selectedIndex = selectedItem ? items.findIndex((x) => x.url === selectedItem.url) : 0;
    
        yield put(setActiveStep(selectedIndex));
      }
    },

    hide: (state) => {
      return {
        ...state,
        isOpen: false,
      }
    },
    _hide: function (returnAction, self) {
      if (window.location.href.includes('?')) {
        window.history.pushState(null, null, window.location.pathname);
      }
    },
  }
);

Reducer.makeLayerSelector = (layer, stateKey) => {
  return Reducer.makeCallbackSelector('layers', (layers) => {
    var target = layers.find(x => x.layer === layer);
    
    return target ? target[stateKey] : false;
  });
};

export default Reducer;

export const reducer = Reducer.reducer;
export const saga = Reducer.saga;