import { createActions, handleActions } from 'redux-actions';
import { LOCATION_CHANGE } from 'connected-react-router';
import { createSelector } from 'reselect';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { makeDispatcher } from '../../utils/store';

import * as Functions from '../../utils/functions';
import * as Constants from '../../utils/constants';

const key = 'Camera';
const playerKey = 'CameraPlayer';

export const actionCreators = createActions(
  'SHOW',
  'INITIALIZE',
  'INITIALIZE_RETURN',
  'SET_STREAM',
  'CLOSE_STREAM',
  'SET_STATES',
  'CAPTURE',
  'CAPTURE_RETURN',
  'SWITCH_CAMERA',
  'SWITCH_CAMERA_RETURN',
  {
    prefix: key,
  },
);

export const {
  show,
  initialize,
  initializeReturn,
  setStream,
  closeStream,
  setStates,
  capture,
  captureReturn,
  switchCamera,
  switchCameraReturn,
} = actionCreators;


export const initialState = {
  values: false,
  loading: false,
  alert: false,
  result: false,
  stream: null,
  player: null,
  imageUrl: null,
  imageFile: null,
  facingMode: Constants.Camera.FacingMode.User.code,
  isOpen: false,
  payload: {},
};

export const reducer = handleActions(
  {
    [show]: (state, { payload }) => ({
      ...state,
      ...initialState,
      isOpen: true,
      payload: payload,
    }),
    [initialize]: (state, { payload }) => ({
      ...state,
      isOpen: true,
      values: payload,
    }),
    [initializeReturn]: {
      next: (state, { payload }) => ({
        ...state,
        stream: payload.data.stream,
        player: payload.data.player,
      }),
      throw: (state, { payload }) => ({
        ...state,
        alert: payload,
      }),
    },
    [setStream]: (state, { payload }) => ({
      ...state,
      stream: payload,
    }),
    [closeStream]: (state) => {
      if (state.stream) {
        state.stream.getTracks().forEach(function(track) {
          track.stop();
        });
      }
      return {
        ...state,
        isOpen: false,
      }
    },
    [capture]: (state, { payload }) => ({
      ...state,
      values: payload,
    }),
    [captureReturn]: {
      next: (state, { payload }) => ({
        ...state,
        imageUrl: payload.data.imageUrl,
        imageFile: payload.data.imagefile,
      }),
      throw: (state, { payload }) => ({
        ...state,
        alert: payload,
      }),
    },
    [setStates]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [switchCamera]: (state, { payload }) => ({
      ...state,
      values: payload,
    }),
    [switchCameraReturn]: {
      next: (state, { payload }) => ({
        ...state,
        facingMode: payload.data.facingMode,
      }),
      throw: (state, { payload }) => ({
        ...state,
        alert: payload,
      }),
    },
    [LOCATION_CHANGE]: () => initialState,
  },
  initialState,
);

export const originSelector = state => state[key] || initialState;
export const makeSelector = (stateKey) => createSelector(originSelector, state => state[stateKey]);

export function* _initialize() {
  const values = yield select(makeSelector('values'));
  const payload = yield call(
    Functions.Browser.initializeVideoStream,
    playerKey,
    values.facingMode,
  );

  yield put(initializeReturn({
    result: true,
    data: {
      stream: payload.stream,
      player: payload.player,
    }
  }));
}

export function* _capture() {
  const player = yield select(makeSelector('player'));
  const payload = yield select(makeSelector('payload'));

  var canvas = document.createElement("canvas");
  canvas.width = player.videoWidth;
  canvas.height = player.videoHeight;
  var context = canvas.getContext("2d");
  context.drawImage(player, 0, 0, canvas.width, canvas.height);

  // convert to Blob (async)
  canvas.toBlob((blob) => {
    const file = new File( [ blob ], "camera.png");
    const url = URL.createObjectURL(blob);

    dispatcher.captureReturn({
      result: true,
      data: {
        imageUrl: url,
        file: file,
      },
    })
    dispatcher.closeStream();

    
    if (payload.onResultCallback) {
      payload.onResultCallback(file);
    }
  });
}

export function* _switchCamera() {
  const facingMode = yield select(makeSelector('facingMode'));
  var newFacingMode = facingMode;

  if (facingMode === Constants.Camera.FacingMode.User.code) {
    newFacingMode = Constants.Camera.FacingMode.Environment.code;
  }
  else if (facingMode === Constants.Camera.FacingMode.Environment.code) {
    newFacingMode = Constants.Camera.FacingMode.User.code;
  }

  yield put(initialize({
    facingMode: newFacingMode,
  }));

  yield put(switchCameraReturn({
    result: true,
    data: {
      facingMode: newFacingMode,
    }
  }));
}

export function* saga() {
  yield takeLatest(initialize, _initialize);
  yield takeLatest(capture, _capture);
  yield takeLatest(switchCamera, _switchCamera);
}

export const dispatcher = makeDispatcher(actionCreators);