import { createActions, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';

import { makeDispatcher } from '../../utils/store';

const key = 'Stepper';

export const actionCreators = createActions(
  'SET_ACTIVE_STEP',
  'NEXT_STEP',
  'BACK_STEP',
  {
    prefix: key,
  }
);

export const {
  setActiveStep,
  nextStep,
  backStep,
} = actionCreators;

export const initialState = {
  values: false,
  activeStep: 0,
};

export const reducer = handleActions({
  [setActiveStep]: (state, { payload }) => ({
    ...state,
    activeStep: payload,
  }),
  [nextStep]: (state) => {
    return {
      ...state,
      activeStep: state.activeStep + 1,
    };
  },
  [backStep]: (state) => {
    return {
      ...state,
      activeStep: state.activeStep - 1,
    };
  },
}, initialState);

export const originSelector = state => state[key] || initialState;
export const makeSelector = (stateKey) => createSelector(originSelector, state => state[stateKey]);

export function* saga() {}

export const dispatcher = makeDispatcher(actionCreators);