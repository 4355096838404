import callApi from './axios';
import { createQueryParams } from '../utils/api';

export const getProfilesByQuery = async (searchParams = {}) => {
  const queryParams = createQueryParams(searchParams);
  const endpoint = `profiles?${queryParams}`;
  return await callApi('GET', endpoint);
};

export const getFriendsByCurrentProfile = async () => {
  const endpoint = 'users/me/profiles/friends';
  return await callApi('GET', endpoint);
};

export const createFriendRequest = async (body = {}) => {
  const endpoint = 'users/me/profiles/friend_requests';
  return await callApi('POST', endpoint, body);
};

const api = {
  getProfilesByQuery,
  getFriendsByCurrentProfile,
  createFriendRequest,
};

export default api;
