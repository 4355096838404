import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

import './LoadingIndicator.scss';

const key = 'LoadingIndicator';

const LoadingIndicator = props => {
  const { loading } = props;

  return (
    <>
      {
        loading &&
        <section className={key}>
          <div className="wrapper">
            <div className="overlay"></div>
            <div className="loading-indicator">
              <CircularProgress />
            </div>
          </div>
        </section>
      }
    </>
  );
};

export default LoadingIndicator;