import callApi from './axios';
import { createQueryParams } from '../utils/api';

export const getEstimationDetailsByQuery = async (
  cover_estimation_id,
  searchParams = {}
) => {
  const queryParams = createQueryParams(searchParams);
  const endpoint = `cover_estimations/${cover_estimation_id}/estimation_details?${queryParams}`;
  return await callApi('GET', endpoint);
};

export const createEstimationDetail = async (
  cover_estimation_id,
  body = {}
) => {
  const endpoint = `cover_estimations/${cover_estimation_id}/estimation_details`;
  return await callApi('POST', endpoint, body);
};

const api = { getEstimationDetailsByQuery, createEstimationDetail };

export default api;
