import React, { useEffect, memo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { useInjectReducer, useInjectSaga } from '../../utils/injectors';
import {
  actionCreators,
  makeSelector,
  reducer,
  saga,
} from './Stepper.reducer';

import {
  Stepper as MuiStepper,
  StepLabel,
  Step,
  Button,
} from '@mui/material';

import './Stepper.scss';

const key = "Stepper";

const Stepper = props => {
  const {
    steps,
    stepPanels,
    activeStep,
    displayRange,
    notBackStep,
    useActions,
    hideStepper,

    nextStep,
    backStep,
  } = props;

  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNext = () => {
    var targetForm = document.querySelector('.stepper-form');
    if (targetForm) {
      targetForm.dispatchEvent(new Event(
        'submit',
        { cancelable: true, bubbles: true }
      ));
    }

    nextStep();
  };

  const handleBack = () => {
    backStep();
  };

  var _steps = displayRange ? steps.slice(displayRange[0], displayRange[1] + 1) : steps;
  var _stepPanels = displayRange ? stepPanels.slice(displayRange[0], displayRange[1] + 1) : stepPanels;

  return (
    <>
      <section className={key}>
        {
          !hideStepper &&
          <MuiStepper className="stepper" activeStep={activeStep} alternativeLabel>
            {_steps.map((label) => (
              <Step key={label} className="step">
                <StepLabel className="step-label">{label}</StepLabel>
              </Step>
            ))}
          </MuiStepper>
        }

        <div className="instructions">
          {_stepPanels[activeStep]}
        </div>
        {
          !useActions || activeStep === steps.length - 1 ?
            <></> :
            <div className="action-group text-center">
              <Button
                disabled={activeStep === 0 || notBackStep === activeStep}
                onClick={handleBack}
                className="backButton"
                color="default"
              >
                戻る
              </Button>
              <Button type="submit" variant="contained" color="primary" onClick={handleNext}>
                次へ
              </Button>
            </div>
        }
      </section>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  activeStep: makeSelector('activeStep'),
});

export function mapDispatchToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo,
)(Stepper);