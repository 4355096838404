import axios from 'axios';
import * as Auths from '../utils/auth';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
});

axiosInstance.interceptors.request.use(
  (config) => {
    config.headers['Access-Control-Allow-Origin'] = process.env.REACT_APP_CORS_ORIGIN;
    config.headers['Authorization'] = Auths.getToken() || Math.random();
    config.headers['Content-Type'] = 'application/json';
    config.headers['Access-Control-Allow-Credentials'] = true;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const callApi = async (method, endpoint, data = null) => {
  try {
    let response;
    if (method === 'GET') {
      response = await axiosInstance.get(endpoint);
    } else if (method === 'POST') {
      response = await axiosInstance.post(endpoint, data);
    } else if (method === 'PATCH') {
      response = await axiosInstance.patch(endpoint, data);
    } else  if (method === 'PUT') {
        response = await axiosInstance.put(endpoint, data);
    } else {
      throw new Error('Unsupported HTTP method');
    }
    return response.data;
  } catch (error) {
    throw error;
  }
};

export default callApi;
