import React from 'react';
import {
  Grid,
  Icon,
  Avatar as MuiAvatar,
  IconButton,
} from '@mui/material';

import './FileViewer.scss';
import * as Constants from '../../utils/constants';

const key = 'FileViewer';

const FileViewer = props => {
  const {
    viewMode,
    url,

    onDeleteCallback,
  } = props;

  return (
    <>
      <section className={key}>

        {
          !viewMode &&
          <div className='controls'>

            <IconButton
              className='control-item'
              onClick={() => {
                window.open(url, '_blank');
              }}
              size="small"
            >
              <Icon className='icon' size="small">download</Icon>
            </IconButton>

            <IconButton
              className='control-item'
              onClick={onDeleteCallback}
              size="small"
            >
              <Icon className='icon' size="small">delete</Icon>
            </IconButton>
          </div>
        }


        <Adapter {...props} />

      </section>
    </>
  );
};

export default FileViewer;


const Adapter = props => {
  const {
    fileType,
    fileName,
    url,
    disablePlayer,
    thumbnail,

    onAdapterClick,
  } = props;

  const isImage = Constants.File.FileType.Image.extensions.includes(fileType);
  const isAudio = Constants.File.FileType.Audio.extensions.includes(fileType);
  const isVideo = Constants.File.FileType.Video.extensions.includes(fileType);
  const isOther = !isImage && !isAudio && !isVideo;

  return (
    <div className='adapter' onClick={onAdapterClick}>
      {
        isImage &&
        <MuiAvatar
          className="file-image"
          variant={'square'}
          sx={{
            width: thumbnail ? thumbnail : '100%',
            height: thumbnail ? thumbnail : 'auto',
          }}
          src={url}
          alt={fileName}
        />
      }

      {
        isAudio &&
        <>
          {
            disablePlayer ?
              <Icon className='adapter-icon' color='primary'>audio_file</Icon> :
              <div className="file-audio">
                <audio controls >
                  <source src={url} type="audio/mp3" />
                  Your browser does not support the audio element.
                </audio>
              </div>
          }
        </>

      }

      {
        isVideo &&
        <div className="file-video">
          <video preload="metadata" controls={!disablePlayer}>
            <source src={url + '#t=0.1'} type="video/mp4" />
            Your browser does not support the video element.
          </video>
        </div>
      }
      {
        isOther &&
        <>
          <Grid
            className="file-other"
            container
            wrap={'nowrap'}
            alignItems='center'
            justifyContent='center'
            spacing={1}
          >

            <Grid item xs={4}>
              <MuiAvatar
                className="file-image"
                variant={'square'}
                sx={{
                  width: '90px',
                  height: '90px',
                }}
                src={'/images/icon-file.png'}
                alt={fileName}
              />
            </Grid>

            <Grid className='info' item xs={8}>
              <div className='file-name dot-overflow'>{fileName}</div>
            </Grid>

          </Grid>
        </>
      }

    </div>
  );
};