import React, { useEffect, memo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { useInjectReducer, useInjectSaga } from '../../utils/injectors';
import {
  actionCreators,
  makeSelector,
  reducer,
  saga,
} from './Camera.reducer';

import classNames from "classnames";
import {
  Icon,
  IconButton,
} from '@mui/material';

import './Camera.scss';
import * as Constants from '../../utils/constants';

const key = 'Camera';
const playerKey = 'CameraPlayer';

const Camera = props => {
  const {
    className,
    imageUrl,
    isOpen,

    initialize,
    capture,
    switchCamera,
    closeStream,
  } = props;

  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  useEffect(() => {
    if (isOpen) {
      initialize({
        facingMode: Constants.Camera.FacingMode.Environment.mode,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <section
      className={classNames(
        key,
        className,
        isOpen ? 'Opening' + key : 'hidden'
      )}
    >
      <div className='camera-player'>

        <video id={playerKey}></video>

        {
          isOpen &&
          <div className='player-overlay'>

            <div className='image-overlay'>
              {
                imageUrl &&
                <div className='image-view'>
                  <img src={imageUrl} alt="view" />
                </div>
              }
            </div>

            <div className='top-controls'>
              <IconButton
                onClick={() => {
                  switchCamera();
                }}
              >
                <Icon className='icon'>cameraswitch</Icon>
              </IconButton>

              <IconButton
                onClick={() => {
                  closeStream();
                }}
              >
                <Icon className='icon'>close</Icon>
              </IconButton>
            </div>

            <div className='bottom-controls'>
              <IconButton
                onClick={() => {
                  capture();
                }}
              >
                <Icon className='icon'>camera</Icon>
              </IconButton>
            </div>

          </div>
        }

      </div>
    </section>
  );
};

const mapStateToProps = createStructuredSelector({
  isOpen: makeSelector('isOpen'),
  stream: makeSelector('stream'),
  imageUrl: makeSelector('imageUrl'),
  player: makeSelector('player'),
});

export function mapDispatchToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo,
)(Camera);